<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'600px'"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-upload
        class="upload-demo"
        ref="upload"
        :action="`${serverApi}/supplier/importSupplier`"
        :headers="headers"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        :file-list="fileList"
        :auto-upload="false"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <el-button
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="submitUpload"
          >导入数据</el-button
        >
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import base from "./dialog";
import getConfig from "@/config";
import Store from "@/store";
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  serverApi: "",
  props: {},
  data() {
    return {
      fileList: [],
      headers: { "X-Auth-Token": Store.state.user.token },
    };
  },
  created() {},
  methods: {
    show() {
      this.dialogFormVisible2 = true;
      this.$nextTick((_) => {
        this.dialogFormVisible = true;
      });
    },
    close() {
      this.$nextTick((_) => {
        this.dialogFormVisible2 = false;
      });
    },
    upload() {
      this.serverApi = getConfig().serverApi;
      this.titleTable = this.$t("global.import");
      this.show();
    },
    handleSuccess() {
      this.$message.success(this.$t("global.importOk"));
      this.close()
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>
