<script>
import api from '@/api'
import tool, { treearr } from '@/tool'
import base from '@/templates/list'
import MyDialog from './dialog'
import MyDialog2 from './dialog-upload'
import MyDialog3 from './dialog-info'
export default {
  directives: {},
  filters: {},
  components: {
    MyDialog,
    MyDialog2,
    MyDialog3
  },
  extends: base,
  props: {},
  data () {
    return {
      showExport: false,
      handleTableColumnWidth: 225,
      tableAction: {
        add: { show: true, title: 'global.add' },
        import: { show: true, title: 'global.import' }
      },
      showList: [
        {
          copName: 'el-input',
          placeholder: 'list.物料名称',
          key: 'materialName',
          name: '',
          attrs: ['placeholder']
        },
        {
          copName: 'el-select',
          key: 'materialType',
          type: '',
          attrs: ['placeholder', 'options'],
          placeholder: 'list.物料信息',
          options: [
            { label: this.$t('global.材料'), value: '1' },
            { label: this.$t('global.辅料'), value: '2' },
            { label: this.$t('global.包装材料'), value: '3' },
            { label: this.$t('global.联产品'), value: '4' },
            { label: this.$t('global.废弃物'), value: '5' }

          ],
          width: 180
        },
        {
          copName: 'el-date-picker',
          placeholder: 'list.采购年度',
          key: 'year',
          dates: [],
          attrs: ['copType', 'valueFormat', 'placeholder'],
          copType: 'year',
          valueFormat: 'yyyy-MM-dd'
        }
      ],
      formInline: {
        query: '',
        status: '',
        name: '',
        roleId: '',
        orgId: '',
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1
        }
      },
      tableTile: [
        {
          key: '1',
          columnLabel: 'list.材料类型',
          prop: 'materialType',
          width: ''
        },
        {
          key: '2',
          columnLabel: 'list.材料名称',
          prop: 'materialName',
          width: ''
        },
        {
          key: '3',
          columnLabel: 'list.采购年度',
          prop: 'year',
          width: ''
        },
        {
          key: '4',
          columnLabel: 'list.年度采购总量',
          prop: 'totalAnnualPurchaseValue',
          width: ''
        },
        {
          key: '5',
          columnLabel: 'list.物料单位',
          prop: 'annualPurchaseDetailVOList[0].unit',
          width: '',
          formatter: (row, column, ddd) => {
            var map = {
              0: '启用',
              1: '停用'
            }
            return map[0]
          }
        },
        {
          key: '6',
          columnLabel: 'list.单位原材料PCF',
          prop: 'annualPurchaseDetailVOList[0].pcfSource',
          width: '',
          formatter: (row, column, ddd) => {
            var map = {
              0: '启用',
              1: '停用'
            }
            return map[0]
          }
        }
      ]
    }
  },
  created () {
    // api.organization.queryOrgTree({}).then(res => {
    //   this.showList[3].cascaderOptions = treearr(res.data)
    // })
    // api.auth.getAuthRoleAllRole().then(res => {
    //   this.showList[2].options = res.data.map(item => ({ ...item, label: item.roleName, value: item.id }))
    // })
  },
  methods: {
    init () {
      this.toPage(1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    onSubmit () {
      this.loading = true
      api.annualPurchase
        .queryAnnualPurchasePage({
          page: this.formInline.page.currentPage,
          size: this.formInline.page.size,
          queryParam: {
            ...this.formInline
          }
        })
        .then((res) => {
          this.tableData = res.data.records
          this.formInline.page.total = res.data.total
          this.loading = false
        })
    },

    // 新增
    add () {
      this.$router.push('/annual-purchase/add')
    },
    importClick () {
      console.log(this.$refs.myDialog2)
      this.$refs.myDialog2.upload()
    },

    // 删除
    async remove (id) {
      await this.$confirm(this.$t('global.removeTooltip'), {
        type: 'warning'
      })
      await api.auth.deleteSupplierById({
        id
      })
      this.toPage(1)
    },

    // 编辑
    async edit (id) {
      const res = await api.auth.findSupplierById({ id })
      this.$refs.myDialog.edit({
        ...(res.data || {})
      })
    },

    // 详情
    async view (id) {
      const res = await api.auth.findSupplierById({ id })
      this.$refs.myDialog3.view({
        ...(res.data || {})
      })
    },

    // 启用
    async enable (id) {
      await this.$confirm(this.$t('global.enableTooltip'), { type: 'warning' })
      await api.auth.postAuthUpdateUserStatus({ id: id, status: 1 })
      this.$message.success(this.$t('global.handleOk'))
      this.toPage(1)
    },

    // 停用
    async disable (id) {
      await this.$confirm(this.$t('global.disableTooltip'), {
        type: 'warning'
      })
      await api.auth.postAuthUpdateUserStatus({ id: id, status: 0 })
      this.$message.success(this.$t('global.handleOk'))
      this.toPage(1)
    }
  }
}
</script>
