<template>
  <div v-if="dialogFormVisible2">
    <el-dialog
      :title="titleTable"
      :visible.sync="dialogFormVisible"
      :width="'600px'"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceInfoForm"
        :rules="rules"
        ref="form"
        :label-width="'150px'"
        label-position="right"
        :class="{ 'my-form-readonly': isModelInfo }"
        size="large"
      >
        <el-row>
          <el-col :span="24" :offset="0">
            <div class="form-lable">{{ $t("Supplier.基础信息") }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="supplierName"
              :label="$t('Supplier.供应商名称') + '：'"
            >
              <span>{{deviceInfoForm.supplierName}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="supplierContact"
              :label="$t('Supplier.联系人') + '：'"
            >
              <span>{{deviceInfoForm.supplierContact}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              prop="businessLicenseNo"
              :label="$t('Supplier.营业执照号码') + '：'"
            >
              <span>{{deviceInfoForm.businessLicenseNo}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="supplierContactPhone"
              :label="$t('Supplier.联系人电话') + '：'"
            >
              <span>{{deviceInfoForm.supplierContactPhone}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="remark" :label="$t('add.备注') + '：'">
              <span>{{deviceInfoForm.remark}}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              prop="supplierContactEmail"
              :label="$t('Supplier.联系人邮箱') + '：'"
            >
              <span>{{deviceInfoForm.supplierContactEmail}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" :offset="0">
            <div class="form-lable">{{ $t("Supplier.运输节点") }}</div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :span="24"
            :offset="1"
            v-for="(item, i) in supplierTransportList"
            :key="i"
          >
            <div class="nodeName">
              <div class="nodeIndex">{{ i + 1 }}</div>
              <span class="nodeInput">{{item.fromPlace}}</span>
            </div>
            <el-row :gutter="20" v-if="i != supplierTransportList.length - 1">
              <el-col :span="24" :offset="3">
                <div class="nodeType">
                  <span class="nodeInput">{{item.distance}}</span>
                  <span>{{item.transportType}}</span>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div v-if="isModelInfo">
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="close()"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
        <div v-else>
          <el-button @click="close" size="small">{{
            $t("global.cancel")
          }}</el-button>
          <el-button
            type="primary"
            :loading="posting"
            size="small"
            @click="submitForm('form')"
          >
            {{ $t("global.confirm") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from './dialog'
export default {
  directives: {},
  filters: {},
  loading: false,
  extends: base,
  props: {},
  data () {
    return {
      dialogFormVisible2:false
    }
  },
  created () {

  },
  methods: {
    show() {
      this.dialogFormVisible2 = true;
      this.$nextTick((_) => {
        this.dialogFormVisible = true;
      });
    },
    view(data){
      this.titleTable = this.$t("Supplier.供应商详情");
      this.deviceInfoForm = { ...data };
      if (data.supplierTransportList.length > 0) {
        this.supplierTransportList = data.supplierTransportList;
        this.supplierTransportList.push({
          fromPlace:
            data.supplierTransportList[data.supplierTransportList.length - 1]
              .toPlace,
        });
      }
      this.show()
    }
  }
}
</script>
